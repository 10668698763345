/* 首页 */
const Home = () => import('@/views/home/Index') // 首页
const Notice = () => import('@/views/home/Notice') // 通知页
const NoLand = () => import('@/views/home/NoLand') // 无农场的空页面

export default [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页',
    },
  },
  {
    path: '/notice',
    name: 'Notice',
    component: Notice,
    meta: {
      title: '公告',
    },
  },
  {
    path: '/noLand',
    name: 'NoLand',
    component: NoLand,
    meta: {
      title: '暂无农场',
    },
  },
]
