import Vue from 'vue'
import request from '@/fetch/request'

const actions = {
  // 获取用户基本位置信息
  getUserLocationInfo({ commit }) {
    const geolocation = new AMap.Geolocation({
      // 是否使用高精度定位，默认：true
      enableHighAccuracy: true,
      // 设置定位超时时间，默认：无穷大
      timeout: 10000,
      // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
      buttonOffset: new AMap.Pixel(10, 20),
      // 定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
      zoomToAccuracy: false,
      // 定位按钮的排放位置,  RB表示右下
      buttonPosition: 'RB',
    })

    geolocation.getCurrentPosition()
    AMap.event.addListener(geolocation, 'complete', onComplete)
    AMap.event.addListener(geolocation, 'error', onError)

    //返回定位信息
    function onComplete(data) {
      // data是具体的定位信息
      console.log('---------location success: ', data)
      const {
        addressComponent: { country, province, city, district, township, adcode },
        position: { lat: latitude, lng: longitude },
        formattedAddress,
      } = data

      commit('UPDATE_USER_LOCATION_INFO', {
        longitude,
        latitude,
        country,
        province,
        city,
        district,
        township,
        adcode,
        formattedAddress,
      })
    }

    // 返回定位出错信息
    function onError(err) {
      console.log('---------location error: ', err)
      this.$message('获取位置信息失败')
    }
  },

  // 获取当前登录用户基本用户信息
  async getLoginUserInfo({ commit }) {
    try {
      const res = await request.get('/personal/userInfo')
      commit('UPDATE_USER_LOGIN_INFO', res)
    } catch (err) {
      Vue.prototype.$message.error(err.message || '用户信息获取失败')
    }
  },
}
export default actions
