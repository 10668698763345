/* 数字证书 */
const DigitalBigScreen = () => import('@/views/digitalBigScreen/Index')

export default [
  {
    path: '/digitalBigScreen',
    name: 'DigitalBigScreen',
    component: DigitalBigScreen,
    meta: {
      title: '数字大屏',
    },
  },
]
