/* 个人中心 */
const PersonalCenter = () => import('@/views/personalCenter/Index')
const RealName = () => import('@/views/personalCenter/realName/Index')
const ChangePassword = () => import('@/views/personalCenter/changePassword/Index')

export default [
  {
    path: '/personalCenter',
    name: 'PersonalCenter',
    component: PersonalCenter,
    meta: {
      title: '个人中心',
    },
  },
  {
    path: '/realName',
    name: 'RealName',
    component: RealName,
    meta: {
      title: '实名认证',
    },
  },
  {
    path: '/changePassword',
    name: 'ChangePassword',
    component: ChangePassword,
    meta: {
      title: '修改密码',
    },
  },
]
