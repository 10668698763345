const Demo = () => import('@/views/demo/Index')

export default [
  {
    path: '/demo',
    name: 'Demo',
    component: Demo,
    meta: {
      title: 'demo演示页面',
    },
  },
]
