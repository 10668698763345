<template>
  <div class="topbar-wrapper">
    <div class="left">
      <i class="back-icon el-icon-arrow-left" @click="handleBack" v-if="needBack"></i>
      <span class="title">{{ title }}</span>
    </div>
    <div class="right">
      <el-popover placement="bottom" width="160" trigger="hover">
        <img class="logo" src="@/assets/images/login/logo_2.png" slot="reference" />
        <ul class="dropdown-list">
          <li class="list-item" v-for="(item, index) in menuList" :key="index" @click="jumpTo(item)">
            <p>{{ item.name }}</p>
          </li>
        </ul>
      </el-popover>
      <span class="mobile">{{ mobile | hideMobile }}</span>
    </div>
  </div>
</template>

<script>
/* 顶部导航条组件 */
import { mapState } from 'vuex'
import Cookie from 'js-cookie'

export default {
  data() {
    return {
      // 下拉导航菜单列表
      menuList: [
        {
          name: '个人中心',
          path: '/personalCenter',
        },
        {
          name: '退出登录',
          path: '/login',
          fn: this.logout,
        },
      ],
    }
  },
  props: {
    // 页面标题
    title: {
      type: String,
      default: '',
    },
    backUrl: {
      type: String,
      default: '',
    },
    // 是否显示返回按钮
    needBack: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      // 用户手机号
      mobile: (state) => state.userLoginInfo.mobile,
    }),
  },
  filters: {
    hideMobile(value) {
      const str = value.toString()
      return str.substr(0, 3) + '****' + str.substr(7)
    },
  },
  methods: {
    // 返回上一页
    handleBack() {
      if (this.backUrl) this.$router.push(this.backUrl)
      else this.$router.back()
    },
    // 跳转个人中心某个页面
    jumpTo(item) {
      let promiseCallBack = Promise.resolve(true)
      if (item.fn) {
        promiseCallBack = item.fn()
      }
      promiseCallBack.then((status) => {
        if (item.path && status) {
          this.$router.push(item.path)
        }
      })
    },
    // 退出登录
    logout() {
      return this.$request
        .get('/login/logout')
        .then(() => {
          Cookie.remove('web_farmcredit_management_system_token', { path: '' })
          return true
        })
        .catch(() => {
          return false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.topbar-wrapper {
  width: 100%;
  height: 64px;
  background: #ffffff;
  padding: 0 32px;
  box-shadow: 0px 2px 20px 0px rgba(72, 87, 109, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    .back-icon {
      margin-right: 8px;
      font-size: 18px;
      font-weight: bold;
      &:hover {
        cursor: pointer;
      }
    }
    .title {
      font-size: 16px;
      font-weight: 500;
      font-family: PingFangSC-Medium, PingFang SC;
      color: #333;
    }
  }
  .right {
    height: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo {
      margin-right: 7px;
      width: 41px;
      height: 41px;
      &:hover {
        cursor: pointer;
      }
    }
    span {
      display: inline-flex;
      align-items: center;
      /deep/.el-popover__reference-wrapper {
        display: inline-flex;
        align-items: center;
      }
    }
  }
}
</style>
<style lang="scss">
.el-popover {
  padding: 0;
  .dropdown-list {
    padding: 0;
    .list-item {
      height: 50px;
      padding: 0 15px;
      list-style: none;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333;
      p {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
      }
      &:not(:last-child) {
        p {
          border-bottom: 1px solid #f0f0f0;
        }
      }
      &:hover {
        cursor: pointer;
        background-color: #effcf0;
      }
    }
  }
}
</style>
