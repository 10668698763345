<template>
  <div class="nav-menu-wrapper">
    <div class="title-area" @click="toHome">
      <img class="logo" src="@/assets/images/common/logo.png" />
    </div>
    <el-menu
      ref="elMenu"
      :unique-opened="true"
      :default-active="defaultActive"
      class="nav-menu"
      @open="handleOpen"
      @close="handleClose"
    >
      <div class="submenu-wrapper" v-for="(memuItem, menuIndex) in menuList" :key="menuIndex">
        <!-- 有二级子菜案 -->
        <el-submenu v-if="memuItem.children" :index="`${menuIndex}`">
          <template slot="title">
            <!-- 引入iconfont字体图标: https://at.alicdn.com/t/project/2607467/0f013d96-b9c4-4446-a4b9-6e5be62c169e.html?spm=a313x.7781069.1998910419.62 -->
            <span :class="['iconfont', 'icon-' + memuItem.iconName]"></span>
            <span class="submenu-title">{{ memuItem.title }}</span>
          </template>
          <el-menu-item-group>
            <el-menu-item
              v-for="(submemuItem, submenuIndex) in memuItem.children"
              :key="submenuIndex"
              :index="`${menuIndex}-${submenuIndex}`"
              @click="handleClickSubMenuItem(submemuItem)"
              >{{ submemuItem.title }}</el-menu-item
            >
          </el-menu-item-group>
        </el-submenu>
        <!-- 无二级子菜案 -->
        <el-menu-item v-else :index="`${menuIndex}`" @click="handleClickMenuItem(memuItem)">
          <span :class="['iconfont', 'icon-' + memuItem.iconName]"></span>
          <span slot="title">{{ memuItem.title }}</span>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>

<script>
/* 左侧导航栏组件 */
export default {
  data() {
    return {
      defaultActive: '', // 如: 0-0(有二级子菜单); 2(无二级子菜单)
      // 导航菜单列表
      menuList: [
        {
          title: '我的农场',
          iconName: 'wodedikuaishou2x',
          children: [
            {
              title: '所有农场',
              path: '/allLands/landList',
            },
            {
              title: '农场管理',
              path: '/landsManagement',
            },
          ],
        },
        {
          title: '产品溯源',
          iconName: 'chanpinsuyuanshou2x',
          children: [
            {
              title: '所有溯源码',
              path: '/allSourceCodes',
            },
            {
              title: '流量分析',
              path: '/flowAnalysis',
            },
          ],
        },
        {
          title: '数字证书',
          iconName: 'shuzizhengshushou2x',
          path: '/digitalCertificate',
        },
        {
          title: '数字大屏',
          iconName: 'shuzidapingshou2x',
          path: '/digitalBigScreen',
        },
        {
          title: '数字基地',
          iconName: 'shuzijidishou2x',
          children: [
            {
              title: '基地动态',
              path: '/baseMonitor',
            },
            {
              title: '设备管理',
              path: '/deviceManagement',
            },
          ],
        },
      ],
    }
  },
  watch: {
    $route(newRoute, oldRoute) {
      const newIndex = newRoute.meta.activeMenuIndex
      const oldIndex = oldRoute.meta.activeMenuIndex
      // 路由跳转时，导航栏激活新页面（如新页面配置在导航栏内）并收起旧页面（如旧页面已在导航栏展开）
      if (newIndex) {
        this.defaultActive = newIndex
      } else {
        this.defaultActive = ''
      }
      if (oldIndex && oldIndex !== newIndex) {
        this.$refs.elMenu.close(oldIndex.substr(0, 1))
      }
    },
  },
  mounted() {
    // 页面刷新时，导航栏默认激活当前页面位置
    if (this.$route.meta.activeMenuIndex) {
      this.defaultActive = this.$route.meta.activeMenuIndex
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    toHome() {
      this.$router.push({ path: '/home' })
    },
    // 点击一级菜单
    handleClickMenuItem(memuItem) {
      console.log('----------memuItem: ', memuItem)
      this.$router.push({ path: memuItem.path })
    },
    // 点击二级子菜单
    handleClickSubMenuItem(submemuItem) {
      console.log('----------submemuItem: ', submemuItem)
      this.$router.push({ path: submemuItem.path })
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-menu-wrapper {
  width: 207px;
  height: 100vh;
  background-color: #16b15c;
  .title-area {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    .logo {
      width: 119px;
      height: 37px;
    }
    .title {
      font-size: 26px;
      line-height: 30px;
      font-family: PangMenZhengDao;
      color: #ffffff;
    }
  }
  .nav-menu {
    border-right: none;
    background: #16b15c;
    /deep/ .submenu-wrapper {
      .el-submenu {
        .el-submenu__title {
          .iconfont {
            margin-right: 5px;
          }
          .el-icon-arrow-down::before {
            content: '\e790';
            color: #16b15c;
          }
          color: #eee;
          &:hover {
            background-color: #effcf0;
            color: #16b15c;
          }
        }
        .el-menu {
          .el-menu-item {
            color: #16b15c;
            &.is-active {
              background-color: #effcf0;
            }
            &:focus,
            &:hover {
              background-color: #effcf0;
            }
          }
        }
        &.is-active,
        &.is-opened {
          background-color: #fff;
          .el-submenu__title {
            color: #16b15c;
          }
        }
      }
      & > .el-menu-item {
        color: #fff;
        .iconfont {
          margin-right: 5px;
        }
        &:hover {
          background-color: #effcf0;
          color: #16b15c;
        }
        &.is-active {
          background-color: #effcf0;
          color: #16b15c;
        }
      }
    }
  }
}
</style>
