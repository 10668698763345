const mutations = {
  // 更新高德地图 JSAPI 加载状态
  UPDATE_AMAP_STATUS(state, payload) {
    state.amapIsReady = payload
  },
  // 更新用户基本位置信息
  UPDATE_USER_LOCATION_INFO(state, payload) {
    state.userLocationInfo = payload
  },
  // 更新当前登录用户基本用户信息
  UPDATE_USER_LOGIN_INFO(state, payload) {
    state.userLoginInfo = payload
  },
  // 存储 mapbox 地图实例
  SAVE_MAPBOX_INSTANCE(state, payload) {
    state.mapboxInstance = payload
  },
}

export default mutations
