/* 标记农场页面数据 */
const state = {
  /*
   * 农场边界信息 -- 编辑时用
   * Note:
   * 1. markLandData 数据结构为 mapbox-gl-draw 支持的数据结构
   * 2. 当前仅支持一次画一个农场
   */
  markLandData: [
    {
      geometry: {
        // 农场边界点坐标集(首尾必须为同一个点)
        coordinates: [
          // [
          //   [106.53762222752283, 29.861785462978546],
          //   [106.42981888279655, 29.72830912190767],
          //   [106.65023209080442, 29.72830912190767],
          //   [106.53762222752283, 29.861785462978546],
          // ],
        ],
        type: 'Polygon', // 农场类型为多边形
      },
      // id: '43fb404be15156de968c6835da231bc8',
      type: 'Feature',
      // properties: {},
    },
  ],
  // 农场中心点四级地址 -- 创建时用
  landCenterAddress: {
    province: '', // 省
    city: '', // 市
    district: '', // 区(县)
    township: '', // 街道(镇)
  },
  landArea: null, // 农场面积(亩) -- 创建时用
}

const mutations = {
  // 设置农场边界点信息
  SET_MARKLAND_DATA(state, payload) {
    let polygon = payload.map((item) => [item.longitude, item.latitude])
    polygon.push(polygon[0])
    state.markLandData[0].geometry.coordinates = [polygon]
  },
  // 设置农场面积(亩)
  SET_LAND_AREA(state, payload) {
    state.landArea = payload
  },
  // 设置农场中心点四级地址
  SET_LAND_CENTER_ADDRESS(state, payload) {
    state.landCenterAddress = payload
  },
  // 清除农场信息[边界点/面积/中心点地址]
  CLEAR_MARKLAND_DATA(state) {
    state.markLandData[0].geometry.coordinates = []
    state.landArea = null
    state.landCenterAddress = {
      province: '',
      city: '',
      district: '',
      township: '',
    }
  },
}

export default {
  name: 'markLand',
  namespaced: true,
  state,
  mutations,
}
