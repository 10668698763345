<template>
  <div id="app">
    <template v-if="$route.path && $route.path !== '/'">
      <template v-if="$route.path === '/login'">
        <router-view :key="$route.fullPath" />
      </template>

      <template v-else>
        <!-- 左侧导航菜单 -->
        <NavMenu />
        <!-- 右边内容区域 -->
        <router-view :key="$route.fullPath" />
      </template>
    </template>
  </div>
</template>

<script>
import NavMenu from '@/components/NavMenu'
import Cookies from 'js-cookie'
export default {
  components: {
    NavMenu,
  },
  data() {
    return {
      city: 'HangZhou',
    }
  },
  mounted() {
    const token = Cookies.get('web_farmcredit_management_system_token')
    // 页面刷新时重新获取用户信息
    if (token && window.location.href.indexOf('/login') === -1) {
      this.$store.dispatch('getLoginUserInfo')
    }
  },
}
</script>

<style lang="scss">
[v-cloak] {
  display: none;
}

html {
  box-sizing: border-box;
}

#app {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #fff;
  display: flex;
}

* {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: PingFangSC-Regular, PingFang SC !important;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  text-align: left;
  background: #fff;
  color: #333;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a:focus,
a:active {
  outline: none;
}

a,
a:focus,
a:hover {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.clearfix {
  &:after {
    content: '';
    height: 0;
    display: block;
    visibility: hidden;
    font-size: 0;
    clear: both;
  }
}
</style>
