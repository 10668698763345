/* 我的农场 */
const LandList = () => import('@/views/myLand/allLands/LandList')
const MarkLand = () => import('@/views/myLand/allLands/MarkLand')
const LandInfo = () => import('@/views/myLand/allLands/LandInfo')
const LandsManagement = () => import('@/views/myLand/landsManagement/Index')

export default [
  {
    path: '/allLands/landList',
    name: 'LandList',
    component: LandList,
    meta: {
      title: '农场列表',
      activeMenuIndex: '0-0', // 用于标识 el-menu 当前激活的菜单
    },
  },
  {
    path: '/allLands/markLand',
    name: 'MarkLand',
    component: MarkLand,
    meta: {
      title: '标记农场',
      activeMenuIndex: '0-0',
    },
  },
  {
    path: '/allLands/landInfo',
    name: 'LandInfo',
    component: LandInfo,
    meta: {
      title: '基本信息',
      activeMenuIndex: '0-0',
    },
  },
  {
    path: '/landsManagement',
    name: 'LandsManagement',
    component: LandsManagement,
    meta: {
      title: '农场管理',
      activeMenuIndex: '0-1',
    },
  },
]
