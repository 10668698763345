/* 首页 */
const Login = () => import('@/views/login/Index') // 首页

export default [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登录',
    },
  },
]
