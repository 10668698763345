import CryptoJS from 'crypto-js'
// 存储海康萤石云平台的appKey/appSecret（加密后）
const hkEncryptedAK = 'U2FsdGVkX1+LEn6GaitWKdqXtd/7+f6M73Yeijbegb3fBtgVHGAP9woWn+PXJEaq3JHFcMvtQgd5IKeqdE0PIg=='
const hkEncryptedSK = 'U2FsdGVkX1+iHZUApbw/7c6whDqKkfbmIeNolAZ72d1343ZeCJBmZfkJuhaYts2LGq312NMJw95tbuu80PllCg=='
// 存储大华乐橙云平台的appId/appSecret（加密后）
const dhEncryptedAppId = 'U2FsdGVkX19Djb95gwf6CNRHavys6cAY3s+LCXkIkyPv0W2uEHlPlQD7Knx5rbTW'
const dhEncryptedSK = 'U2FsdGVkX1+hY/Ja5c/vdERk5vqTdse3T+QwUwTF8MqO6SL0zb4IFmmeua6XzSBv'
// 加/解密口令
const passphrase = 'FCRrRekmNdZn'

/**
 * 加密字符串
 * @param {string} text 需要加密的文本
 * @param {string} passphrase 用于加/解密的口令
 * @return {string} 返回加密后的字符串
 */
function encryptString(text, passphrase) {
  // 使用CryptoJS的AES加密方法加密文本
  let ciphertext = CryptoJS.AES.encrypt(text, passphrase)
  // 返回加密文本的字符串表示
  return ciphertext.toString()
}

/**
 * 解密字符串
 * @param {string} ciphertext 需要解密的文本
 * @param {string} passphrase 用于加/解密的口令
 * @return {string} 返回解密后的字符串
 */
function decryptString(ciphertext, passphrase) {
  // 使用CryptoJS的AES算法解密密文
  let bytes = CryptoJS.AES.decrypt(ciphertext, passphrase)
  // 将解密后的字节序列转换为UTF-8字符串并返回
  return bytes.toString(CryptoJS.enc.Utf8)
}

/**
 * 获取海康萤石云平台的密钥信息
 */
function getHkSecretInfo() {
  let appKey = decryptString(hkEncryptedAK, passphrase)
  let appSecret = decryptString(hkEncryptedSK, passphrase)
  return { appKey, appSecret }
}

/**
 * 获取大华乐橙云平台的密钥信息
 */
function getDhSecretInfo() {
  let appId = decryptString(dhEncryptedAppId, passphrase)
  let appSecret = decryptString(dhEncryptedSK, passphrase)
  return { appId, appSecret }
}

export { encryptString, decryptString, getHkSecretInfo, getDhSecretInfo }
