const DigitalCertificate = () => import('@/views/digitalCertificate/Index') // 首页

export default [
  {
    path: '/digitalCertificate',
    name: 'digitalCertificate',
    component: DigitalCertificate,
    meta: {
      title: '数字认证',
    },
  },
]
