// 需要处理导航状态的路由列表
const linkedRouteList = [
  // 我的农场
  [
    {
      path: '/allLands/landList',
      name: 'LandList',
      activeMenuIndex: '0-0', // 用于标识 el-menu 当前激活的菜单
    },
    {
      path: '/allLands/markLand',
      name: 'MarkLand',
      activeMenuIndex: '0-0',
    },
    {
      path: '/allLands/landInfo',
      name: 'LandInfo',
      activeMenuIndex: '0-0',
    },
    {
      path: '/landsManagement',
      name: 'LandsManagement',
      activeMenuIndex: '0-1',
    },
  ],
  // 产品溯源
  [
    {
      path: '/allSourceCodes',
      name: 'AllSourceCodes',
      activeMenuIndex: '1-0',
    },
    {
      path: '/chooseLand',
      name: 'ChooseLand',
      activeMenuIndex: '1-0',
    },
    {
      path: '/editSourceCode/:id',
      name: 'EditSourceCode',
      activeMenuIndex: '1-0',
    },
    {
      path: '/flowAnalysis',
      name: 'FlowAnalysis',
      activeMenuIndex: '1-1',
    },
  ],
  // 数字证书
  [
    {
      path: '/digitalCertificate',
      name: 'digitalCertificate',
      activeMenuIndex: '2',
    },
  ],
  // 数字大屏
  [
    {
      path: '/digitalBigScreen',
      name: 'DigitalBigScreen',
      activeMenuIndex: '3',
    },
  ],
  // 数字基地
  [
    {
      path: '/baseMonitor',
      name: 'BaseMonitor',
      activeMenuIndex: '4-0',
    },
    {
      path: '/historicalAlbum',
      name: 'HistoricalAlbum',
      activeMenuIndex: '4-0',
    },
    {
      path: '/deviceManagement',
      name: 'DeviceManagement',
      activeMenuIndex: '4-1',
    },
  ],
]

// 数据扁平化处理
function flatten(arr) {
  return arr.reduce((flat, toFlatten) => {
    return flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten)
  }, [])
}

export { linkedRouteList, flatten }
