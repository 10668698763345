/* 产品溯源 */
const SourceCode = () => import('@/views/source/sourceCode/Index')
const FlowAnalysis = () => import('@/views/source/flowAnalysis/Index')
const ChooseLand = () => import('@/views/source/sourceCode/ChooseLand')
const EditSourceCode = () => import('@/views/source/sourceCode/EditSourceCode')

export default [
  {
    path: '/allSourceCodes',
    name: 'AllSourceCodes',
    component: SourceCode,
    meta: {
      title: '所有溯源码',
    },
  },
  {
    path: '/chooseLand',
    name: 'ChooseLand',
    component: ChooseLand,
    meta: {
      title: '选择农场',
    },
  },
  {
    path: '/editSourceCode/:id/:productType',
    name: 'EditSourceCode',
    component: EditSourceCode,
    meta: {
      title: '编辑溯源',
    },
  },
  {
    path: '/flowAnalysis',
    name: 'FlowAnalysis',
    component: FlowAnalysis,
    meta: {
      title: '流量分析',
    },
  },
]
