import axios from 'axios'
import qs from 'qs'
import { getHkSecretInfo } from '@/utils/decrypt'
const { appKey, appSecret } = getHkSecretInfo()

const state = {
  haikangCamera: {
    accessToken: null, //摄像头token
    expireTime: null, //摄像头token过期时间 单位秒
  },
}

const actions = {
  // 获取海康摄像头token
  async getHaiKangCameraToken({ commit }) {
    const params = {
      appKey,
      appSecret,
    }
    // 获取摄像头token
    const cameraInfo = await axios({
      method: 'post',
      url: 'https://open.ys7.com/api/lapp/token/get',
      data: qs.stringify(params),
    })

    commit('SET_HAIKANG_CAMERA_TOKEN', cameraInfo.data.data.accessToken)
    // Math.round(new Date().getTime()/1000) 当前时间
    // cameraInfo.data.result.data.expireTime 过期具体毫秒数
    commit('SET_HAIKANG_CAMERA_TOKEN_EXPIRE_TIME', Math.round(cameraInfo.data.data.expireTime / 1000))
    return new Promise((resolve) => {
      resolve(cameraInfo.data.data.accessToken)
    })
  },
}

const mutations = {
  UPDATE_MENU_TOGGLE(state, payload) {
    state.menuToggle = payload
  },
  UPDATE_USER_MESSAGE(state, payload) {
    state.user = payload
  },
  CLEAR_USER_MESSAGE(state) {
    state.user = {}
  },
  // 获取海康摄像头token
  SET_HAIKANG_CAMERA_TOKEN: async (state, payload) => {
    state.haikangCamera.accessToken = payload
  },
  // 获取海康摄像头token过期时间
  SET_HAIKANG_CAMERA_TOKEN_EXPIRE_TIME: async (state, payload) => {
    // token过期时间(具体过期时间秒数)
    state.haikangCamera.expireTime = payload
  },
  // 设置登录token
  SET_LOGIN_TOKEN: (state, payload) => {
    state.loginToken = payload
  },
  // 清除登录token
  REMOVE_LOGIN_TOKEN: (state) => {
    state.loginToken = null
    state.user = null
  },
}

export default {
  name: 'moduleTest',
  namespaced: true,
  state,
  actions,
  mutations,
}
