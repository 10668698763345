import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 全局引入 echarts 图形库
// import 'echarts'
// 建议手动从 ECharts 引入单个图表和组件。请参考所有支持的渲染器/图表/组件: https://github.com/apache/echarts/blob/master/src/echarts.all.ts

// 注册全局 vue-echarts 组件
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { SVGRenderer } from 'echarts/renderers'
import { RadarChart } from 'echarts/charts'
import { TitleComponent, LegendComponent } from 'echarts/components'
import './utils/china'

use([SVGRenderer, RadarChart, TitleComponent, LegendComponent])
Vue.component('v-chart', ECharts)

// 引入 element-ui 组件（按需引入: 需要的组件在文件element-ui.js中开启）
import './element-ui.js'
// 引入 element-ui 自定义主题
import '../theme/index.css'

// 加载高德地图 JSAPI
import AMapLoader from '@amap/amap-jsapi-loader'
AMapLoader.load({
  key: '7d5d07d8824d24bee96f50e6fea5e8e9', // 领见数农
  version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
  plugins: [
    'AMap.Autocomplete',
    'AMap.PlaceSearch', // 地址搜索
    // 'AMap.Scale',
    // 'AMap.OverView',
    // 'AMap.ToolBar',
    // 'AMap.MapType',
    // 'AMap.PolyEditor',
    // 'AMap.CircleEditor',
    'AMap.Geocoder', // 地理/逆地理编码
    'AMap.Geolocation', // 定位
    // 'AMap.RangingTool',
  ], // 需要使用的的插件列表
})
  .then((AMap) => {
    console.log('高德地图 JSAPI 加载成功: ', AMap)
    store.commit('UPDATE_AMAP_STATUS', true)
    store.dispatch('getUserLocationInfo')
  })
  .catch((e) => {
    console.log('高德地图 JSAPI 加载失败: ', e)
  })

// 页面布局及顶部导航栏组件
import Layout from '@/components/Layout.vue'
import TopBar from '@/components/TopBar.vue'
Vue.component('Layout', Layout)
Vue.component('TopBar', TopBar)

// 配置全局过滤器
import * as filters from './filters'
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

// 全局挂载Ajax请求方法
import request from './fetch/request'
Vue.prototype.$request = request

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

// 监听路由跳转失败的情况
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g
  const isChunkLoadFailed = error.message.match(pattern)
  if (isChunkLoadFailed) {
    window.location.reload()
  }
})
