/* 数字基地 */
const BaseMonitor = () => import('@/views/digitalBase/baseMonitor/Index')
const HistoricalAlbum = () => import('@/views/digitalBase/historicalAlbum/Index')
const DeviceManagement = () => import('@/views/digitalBase/deviceManagement/Index')

export default [
  {
    path: '/baseMonitor',
    name: 'BaseMonitor',
    component: BaseMonitor,
    meta: {
      title: '基地动态',
    },
  },
  {
    path: '/historicalAlbum',
    name: 'HistoricalAlbum',
    component: HistoricalAlbum,
    meta: {
      title: '历史相册',
    },
  },
  {
    path: '/deviceManagement',
    name: 'DeviceManagement',
    component: DeviceManagement,
    meta: {
      title: '设备管理',
    },
  },
]
