import Vue from 'vue'
import VueRouter from 'vue-router'
import Cookie from 'js-cookie'
import store from '../store'
import { linkedRouteList, flatten } from './config'

Vue.use(VueRouter)

import demo from './demo' // Demo 版块路由
import login from './login'
import home from './home' // 首页版块路由
import myLand from './myLand' // 我的农场版块路由
import source from './source' // 产品溯源版块路由
import digitalBase from './digitalBase' // 数字基地版块路由
import digitalBigScreen from './digitalBigScreen' // 数字大屏版块路由
import personalCenter from './personalCenter' // 个人中心版块路由
import digitalCertificate from './digitalCertificate' // 数字证书版块路由

const routes = [
  {
    path: '/',
    redirect: '/home',
  },
  ...demo,
  ...login,
  ...home,
  ...myLand,
  ...source,
  ...digitalBase,
  ...digitalBigScreen,
  ...personalCenter,
  ...digitalCertificate,
]

// 处理侧边导航栏匹配
flatten(linkedRouteList).forEach((item) => {
  const matchIndex = routes.findIndex((route) => route.name && route.name === item.name)
  if (matchIndex) {
    routes[matchIndex].meta.activeMenuIndex = item.activeMenuIndex
  }
})

// 防止跳转同一路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  scrollBehavior: () => ({
    y: 0,
  }),
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  const token = Cookie.get('web_farmcredit_management_system_token')
  const hasLand = store.state.userLoginInfo.massifStatus
  const landCheckWhiteList = [
    '/home',
    '/notice',
    '/realName',
    '/personalCenter',
    '/changePassword',
    '/allLands/landList',
    '/allLands/markLand',
    '/allLands/landInfo',
    '/allLands/landInfo',
    '/noLand', // 加入白名单，避免跳转该页面程序死循环
    '/login', // 加入白名单，避免在登录页程序死循环
  ]

  if (to.query.code) {
    localStorage.setItem('code', to.query.code)
  }

  if (to.path !== '/login' && !token) {
    return next('/login')
  }

  if (to.path === '/login' && token) {
    return next('/home')
  }

  // 与农场有关的页面，无农场的处理
  if (landCheckWhiteList.indexOf(to.path) === -1 && hasLand === false) {
    return next('/noLand')
  }

  next()
})

export default router
